/* Container for the whole page */
.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: #ffffff;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  /* Animation container */
  .successAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    margin-bottom: 16px;
  }
  
  .greenCircle {
    width: 100px;
    height: 100px;
    background-color: #4caf50;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: popIn 0.5s ease-out, pulse 1.5s infinite;
  }
  
  .checkIcon {
    color: white;
    font-size: 48px;
  }
  
  /* Animations */
  @keyframes popIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Success message */
  .successMessage {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
    color: #333;
  }
  
  /* Consultation details section */
  .consultationDetails {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  }
  
  .doctorInfo {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  }
  .doctorInfoTextContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .doctorImage {
    width: 50px;
    height: 50px;
    border-radius: 10%;
    border: 2px solid #d0d0d0;
  }
  
  .doctorName {
    font-size: 16px;
    font-weight: bold;
  }
  
  .doctorDetails {
    font-size: 14px;
    color: #777;
  }
  
  /* Information rows */
  .consultationInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;
  }
  
  .infoRow {
    display: flex;


  }

  .infoRow p {
    font-size: 14px;
    color: #555;
    width: 70%;
    box-sizing: border-box;
  }
  
  .icon {
    margin-right: 8px;
    color: #555;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
  
  /* Notice section */
  .notice {
    display: flex;
    align-items: center;
    background-color: #e8f4ff;
    padding: 12px;
    border-radius: 8px;
    color: #555;
    font-size: 14px;
    width: 100%;
  }
  
  .notice p {
    width: 80%;
  }