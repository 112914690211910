.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.content {
  flex: 1;
  padding-bottom: 100px; /* Additional padding to avoid content overlap with fixed footer */
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #ffffff;
}

.backButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-size: 16px;
  color: black;
  cursor: pointer;
  gap: 8px;
}

.section {
  padding: 16px 24px;
  background-color: #ffffff;
  margin-bottom: 12px;
  border-radius: 8px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
}

.hrTag {
  width: 100%;
  height: 4px;
  background-color: #ecebeb;
  outline: none;
  border: none;
}

.editIcon {
  cursor: pointer;
}

.sectionContent {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.sectionContent span {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
  font-size: 16px;
}

.paymentItem {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
  margin-top: 8px;
}

.strikethrough {
  margin-right: 8px;
  text-decoration: line-through;
  color: #888;
}

.total {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-top: 12px;
}

.dashedHr {
  border: none; /* Remove the default border */
  border-top: 1px dashed #ecebeb; /* Add the dashed border */
  margin: 8px 0; /* Adjust the spacing */
  width: 100%; /* Ensure it spans the full width */
}

.savingsNotice {
  padding: 12px 16px;
  background-color: #e8f5e9;
  color: #388e3c;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  margin: 8px 24px;
}

.savingsNotice span {
  display: flex;
  align-items: center;
}

.refundPolicy {
  display: flex;
  gap: 16px;
  padding: 16px 16px;
  background-color: #fffde7;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 16px;
  margin: 0 24px;
}

.refundPolicyTextContainer {
  flex: 1;
}

.refundPolicy h4 {
  margin-bottom: 4px;
  font-weight: 700;
  color: #f9a825;
}

.refundPolicy p {
  margin: 0;
  color: #555;
}

.returnPolicyImg {
  width: 70px;
  height: 70px;
  border-radius: 10%;
}

/* Floating footer styling */
.bottomBar {
  position: fixed; /* Stick it to the bottom */
  bottom: 0;
  left: 0;
  width: 100%; /* Full width of the viewport */
  box-sizing: border-box;
  background-color: #ffffff; /* White background */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */

  border-top: 1px solid #e0e0e0; /* Subtle separator */
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1); /* Slight elevation effect */
  z-index: 10; /* Ensure it stays on top */
  gap: 12px; /* Space between the payment info and the button */
}

.paymentInfo {
  background-color: #388e3c; /* Green background for payment info */
  color: #ffffff; /* White text for contrast */
  font-size: 16px; /* Slightly larger font */
  padding: 8px 24px; /* Padding inside the green box */
  display: flex; /* Flex layout for aligning text and icon */
  align-items: center; /* Center content vertically */
  width: 100%; /* Stretch to full width */
  justify-content: flex-start; /* Center content horizontally */
  box-sizing: border-box;
}

.nextButton {
  background-color: #6200ea; /* Purple background */
  color: #ffffff; /* White text for contrast */
  font-size: 16px; /* Large font size */
  font-weight: bold; /* Bold text */
  padding: 12px 0; /* Balanced padding */
  width: calc(100% - calc(24px * 2));
  text-align: center; /* Center-align text */
  border-radius: 8px; /* Rounded corners */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
  margin-bottom: 16px;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.nextButton:hover {
  background-color: #4b00b8; /* Slightly darker purple on hover */
}


.outerDiv {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh; /* Makes the container full screen vertically */
   
    background-color: #f0f0f0; /* Light background color, customize as needed */
  }
  
  .container {
    width: 100%;
    max-width: 600px; /* Centers the content on larger screens, but 100% width on mobile/tablets */
    background-color: white; /* Background for the main content */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow for some depth */
  }