/* Container for the entire page */
.successContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    position: relative; /* Allows positioning of back button */
  }
  
  /* Header back button style */
  .backButton {
    position: absolute;
    display: flex;
    align-items: center;
    top: 16px;
    left: 16px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .backIcon{
    margin-right: 8px;
  }

  /* Style for the success/error message */
  .successMessage {
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    margin-bottom: 24px;
  }
  
  /* Highlight the "FREE CONSULTATION" text */
  .highlight {
    font-weight: 700;
  }
  
  /* Container for the icon with two circles */
  .iconContainer {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: popIn 0.5s ease-out forwards;
  }
  
  /* Inner circle for visual effect */
  .innerCircle {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Icon styling */
  .checkIcon {
    color: #fff;
    font-size: 40px;
  }
  
  /* Pop-in animation */
  @keyframes popIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  