/* Main container styling */
.container {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  
  /* Title styling */
  .title {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #333;
    margin-bottom: 12px;
  }
  
  .googleLogo {
    font-family: 'Arial', sans-serif;
    font-weight: 700;
    color: #4285f4;
    margin-right: 8px;
  }
  
  /* Rating styling */
  .rating {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #333;
    margin-bottom: 16px;
  }
  
  .ratingScore {
    font-weight: bold;
    margin-right: 8px;
  }
  
  .stars {
    display: flex;
    margin-right: 8px;
  }
  
  .reviewCount {
    color: #666;
  }
  
 /* Carousel styling */
.carousel {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    box-sizing: content-box;
    padding: 0 16px 16px; /* Add padding to the left and right to prevent shadow clipping */
    scroll-snap-type: x mandatory;
    margin-right: 24px;
 
  }
  
  /* Hide scrollbar */
  .carousel::-webkit-scrollbar {
    display: none;
  }
  
  .carousel {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  

  /* Review card styling */
/* Review card styling */
.reviewCard {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15); /* Shadow shifted to the right and bottom */
    padding: 16px;
    min-width: 250px;
    max-width: 250px;
    flex: 0 0 auto;
    scroll-snap-align: start;
  }
  
  /* Header styling */
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    background-color: #e0e0e0;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .reviewerName {
    font-weight: 600;
    color: #333;
  }
  
  .verifiedIcon {
    color: #4285f4;
    margin-left: 4px;
    font-size: 12px;
  }
  
  .location {
    color: #666;
    font-size: 12px;
  }
  
  /* Review content styling */
  .reviewRating {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .reviewTime {
    color: #666;
    font-size: 12px;
    margin-left: 8px;
  }
  
  .reviewContent {
    color: #333;
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 1.4;
  }
  
  /* Google badge */
  .googleBadge {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }
  
  .googleBadge svg {
    color: #4285f4;
    margin-right: 4px;
  }
  