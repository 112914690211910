.featuredContainer {
  padding: 0px 0;
  overflow: hidden;
  text-align: left;
  margin-bottom: 48px;
}

.title {
  font-size: 28px;
  padding: 24px;
  font-weight: 700;
  color: #6a0dad;
}

.marqueeWrapper {
  overflow: hidden;
  white-space: nowrap; /* Prevents the line from wrapping */
}

.marquee {
  display: inline-flex;
  align-items: center;
  animation: scroll 10s linear infinite; /* Faster scrolling */
}

.logo {
  height: 40px;
  margin-right: 30px;
  flex-shrink: 0; /* Prevents the logos from shrinking */
}

/* Keyframes for smooth horizontal scrolling */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* Moves halfway through the duplicated content */
  }
}
