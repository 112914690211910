/* Container styling */
.bookingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px; /* Increased padding for better spacing */
    width: 100%;
    box-sizing: border-box;
  }
  
.priceDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

  /* Price Section Styling */
  .priceSection {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 16px;
  }
  
  .price {
    font-size: 25px; /* Slightly larger font for the price */
    font-weight: 900;
    color: #000;
  }
  
  /* Book Appointment Button Styling */
  .bookButton {
    background-color: #000000;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 40px; /* Wider padding for a larger button appearance */
    border: none;
    border-radius: 999px; /* Creates the rounded pill shape */
    cursor: pointer;
    margin-bottom: 24px;
    transition: background-color 0.3s ease;
  }
  
  .bookButton:hover {
    background-color: #333333; /* Darker on hover */
  }
  
  /* Refund Policy Styling */
  .refundPolicy {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #34a853; /* Green color for refund text */
   gap: 8px;
  }
  
  .refundIcon {
    font-size: 12px; /* Adjust icon size to match text */
    color: #34a853;
    align-self: flex-start;
  }
  
  .refundText {
    color: #34a853;
    font-size: 11px;
    font-weight: 900;   
    text-align: center;
    line-height: 1.4;
  }
  
