/* Main container styling */
.container {
    border-radius: 12px;
    padding: 20px;
    margin: 0 auto;
  }
  
  /* Title styling */
  .title {
    font-size: 32px;
    font-weight: 700;
    color: #6a0dad; /* Purple color */
    margin-bottom: 24px;
  }
  
  /* Location Tag styling */
  .locationTag {
    display: inline-block;
    background-color: #f5e1f7;
    color: #6a0dad;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 16px;
    margin-bottom: 16px;
  }
  
  /* Clinic Image styling */
  .clinicImage {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  /* Map Link styling */
  .mapLink {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .mapText {
    color: #007bff;
    font-size: 16px;
    font-weight: 900;
    margin-left: 8px;
    text-decoration: none;
  }
  
  .mapText:hover {
    text-decoration: underline;
  }
  
  /* Address styling */
  .address {
    font-size: 14px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 16px;
  }
  
  /* Hours styling */
  .hours {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
    color: #333;
    margin-bottom: 16px;
    gap: 1vw;
  }
  
  .icon {
    color: #333;
  }
  
  /* Book Appointment Button styling */
  .appointmentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #34a853;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .appointmentButton:hover {
    background-color: #2a8f42;
  }
  
  .whatsappIcon {
    margin-right: 8px;
    font-size: 20px;
  }
  