.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  padding-bottom: 20vh;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #ffffff;
}

.backButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-size: 16px;
  color: #6200ea;
  cursor: pointer;
  gap: 8px;
}

.slotSelection {
  background-color: #ffffff;
  padding: 16px 24px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 12px; /* Add space below title */
}

.icon {
  margin-right: 8px;
  color: #707070;
}

.dateContainer {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding: 16px 0; /* Vertical padding for consistent space */
  margin-right: -24px; /* Adjust right margin for alignment */
  padding-right: 24px;
}

.date {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 16px;
  min-width: 60px;
  height: 80px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.dateText {
  font-size: 14px;
  line-height: 1.2;
}

.dateNumber {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.slotsAvailable {
  font-size: 12px;
  line-height: 1.2;
  white-space: nowrap;
}

.selected {
  border: 2px solid #707070 !important;
  font-weight: 900;
}

.notice {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #6200ea;
  background-color: #e8f4ff;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 8px;
  margin-top: 12px; /* Add space above notice */
}

.timeSlotsContainer {
  background-color: #ffffff;
  padding: 16px 24px;
}

.timeSlotTitle {
  font-size: 16px;
  font-weight: bold;
}

.timeSlots {
  display: flex;
  gap: 12px;
  margin-top: 12px;
  flex-wrap: wrap;
}

.timeSlot {
  background-color: #ffffff;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

.footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #e0e0e0;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure it stays on top of other content */
}

.nextButton {
  width: 100%;
  background-color: #6200ea;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.caption{
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.nextButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
