:root {
  --gradient-text-color: -webkit-linear-gradient(#e7d1ff, white);
  --green-gradient-text-color: -webkit-linear-gradient(#d8eee9, white);
  --blue-bg-gradient: linear-gradient(#423da2, #7d78e4);
  --green-bg-gradient: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  --main-blue : #3f3a8e;
  --green : #65a392;
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  /* height: 100vh; */
  overflow-x: hidden;
  font-family: "Nunito";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainLayout {
  width: 100%;
  margin: auto;
}

/* @media (min-width:961px)  { 
  .mainLayout{
    width:  50%;
  }
} */
@font-face {
  font-family: "Oleo Script Bold";
  src: url("./fonts/OleoScript-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Oleo Script Reg";
  src: url("./fonts/OleoScript-Regular.ttf") format("truetype");
}


dl {
  background: #f9f9f9;
  border-radius: 8px;
  padding:  10px;
  margin: 10px auto;
}

dt {
  font-weight: bold;
  color: #333;
  white-space: wrap;
}

dd {
  margin: 0 0 10px 10px;
  color: #666;
  overflow: hidden;
white-space: nowrap;
  text-overflow: ellipsis;
}

dt::after {
  content: ":";
  margin-right: 10px;
}
