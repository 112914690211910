/* Container styling */
.formContainer {
    background-color: #f3e8ff;
    padding: 24px;
  }
  
  /* Heading styling */
  .heading {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 8px;
  }
  
  .price {
    color: #6200ea;
  }
  
  /* Subtext and highlights */
  .subText {
    font-size: 14px;
    color: #757575;
    margin-bottom: 16px;
  }
  
  .highlight {
    color: #34a853;
    font-weight: 600;
  }
  
  /* Label styling */
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-bottom: 8px;
    display: block;
  }
  
  /* Input field styling */
  .inputContainer {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px; /* Adjust padding for even spacing around input */
    box-shadow: 0 0 0 2px transparent; /* Initial box-shadow for focus effect */
    transition: box-shadow 0.2s ease;
  }
  
  .prefix {
    color: #757575;
    font-weight: 500;
    padding-right: 8px;
  }
  
  .divider {
    width: 1px;
    height: 24px;
    background-color: #e0e0e0;
    margin: 0 8px;
  }
  
  .icon {
    color: #34a853;
    margin-right: 8px;
  }
  
  /* Style for the input field */
  .inputField {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    color: #333;
    padding: 4px; /* Padding inside input for a consistent look */
  }
  
  .inputField::placeholder {
    color: #b0b0b0;
  }
  
  /* Custom focus effect */
  .inputContainer:focus-within {
    border-color: #6200ea;
    box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.3); /* Subtle focus shadow */
  }
  

  .hrLine{
    width: 100%;
    height: 1px;
    background-color:black;
  }

  /* Add error styling for input */
.errorInputContainer {
  border-color: #d32f2f;
  box-shadow: 0 0 0 2px rgba(211, 47, 47, 0.3); /* Red focus shadow */
}

/* Error message styling */
.errorMessage {
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
}
