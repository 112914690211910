
/* FertilityClinic.module.css */
.outerDiv {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 100vh; /* Makes the container full screen vertically */
   
    background-color: #f0f0f0; /* Light background color, customize as needed */
  }
  
  .container {
    width: 100%;
    max-width: 600px; /* Centers the content on larger screens, but 100% width on mobile/tablets */
    background-color: white; /* Background for the main content */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow for some depth */
  }
  