/* FertilityClinic.module.css */
.outerDiv {
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 100vh; /* Makes the container full screen vertically */
 
  background-color: #f0f0f0; /* Light background color, customize as needed */
}

.container {
  width: 100%;
  max-width: 600px; /* Centers the content on larger screens, but 100% width on mobile/tablets */
  background-color: white; /* Background for the main content */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow for some depth */
}

@media (min-width: 768px) {
  .container {
    padding: 32px; /* Slightly larger padding on tablet */
  }
}

:global(.fc_heroHeading) {
  font-family: "Nunito";
  font-size: 30px;
  font-weight: 900;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

:global(.fc_pageHeading ){
  font-family: "Nunito";
  font-size: 36.08px;
  font-weight: 700;
  line-height: 40.31px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

:global(.fc_priceHeading) {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

:global(.fc_blogTitle) {
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

:global(.fc_caption ){
  font-family: "Nunito";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

:global(.fc_boldCaption) {
  font-family: "Nunito";;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.heroDiv{
  display: flex;
  align-items: flex-end;
  padding: 0 24px;
  padding-top: 5vh;
  padding-bottom: 3vh;
}
.textContainer{
  flex: 1;
  right: 0;
  align-self: center;
}

.heroImg{
width: 40vw;
position: relative;
margin-bottom: -16px;
left: 0;
bottom: 0;
}

.hrDivider {
  border: none;
  height: 2px;   
  width: 70%;              /* Thickness of the line */
  background-color: #e2dbfc;   /* Light purple color */
  border-radius: 2px;          /* Rounded ends */
  margin: 16px 0;            /* Optional spacing */
}

.heroHeading{
  color: #836BFF;
}