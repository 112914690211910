/* Main container for the consultation method section */
.consultationContainer {
    background-color: #f3e8ff;
    border-radius: 8px;
    margin-top: 24px ;
  }
  
  /* Heading styling */
  .consultationHeading {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 8px;
  }
  

  /* Title container to hold icon and text */
  .titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .icon {
    color: #6200ea;
    margin-right: 8px;
  }
  
  /* Styling for "Offline" in purple and bold */
  .offlineText {
    font-weight: 600;
    font-size: 16px;
    color: #6200ea;
  }
  
  /* Styling for "consultation:" in black and normal weight */
  .consultationText {
    font-weight: 400;
    font-size: 16px;
    color: #000;
  }
  
  /* Location options styling */
  .locationOptions {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  
  /* Individual option styling */
  .option {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: border-color 0.2s;
  }
  
  /* Radio input styling - hide default appearance */
  .option input[type="radio"] {
    appearance: none;
    margin-right: 8px; /* Spacing between radio and text */
    width: 16px;
    height: 16px;
    border: 2px solid #757575;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }
  
  .option input[type="radio"]:checked {
    background-color: #6200ea;
    border-color: #6200ea;
  }
  
  .option input[type="radio"]:checked::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
  }
  
  /* Text styling inside each option */
  .optionText {
    font-size: 14px;
    color: #000;
  }
  
  /* Add border highlight on hover or focus */
  .option:hover, .option input[type="radio"]:focus-visible + .optionText {
    border-color: #6200ea;
  }
  