/* Container styling */
.sectionContainer {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    overflow: hidden; /* Hide horizontal overflow */
    padding-bottom: 8px;
  }
  
  .sectionTitle {
    font-size: 32px;
    font-weight: 700;
    color: #6a0dad;
    margin-bottom: 24px;
  }
  
  /* Horizontal carousel styling */
  .carousel {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 16px 24px;
    scroll-snap-type: x mandatory;
   
    margin-right: -24px;
    padding-left: 24px; /* Adjust padding for better visual balance */
    padding-right: 24px;
  }
  
  /* Hide scrollbar */
  .carousel::-webkit-scrollbar {
    display: none;
  }
  
  .carousel {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
 /* Card styling */
.card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    width: 240px;
    flex: 0 0 auto;
    scroll-snap-align: start;
    overflow: hidden; /* Ensure child elements can't overflow */
    display: flex;
    flex-direction: column;
  }
  
  /* Full-width image styling */
  .cardImage {
    width: 100%;
    height: 160px; /* Adjust as needed */
    object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
  }
  
  /* Content styling inside the card */
  .cardContent {
    padding: 16px; /* Add padding here instead of on the card */
  }
  
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 8px;
  }
  
  .cardDescription {
    font-size: 14px;
    color: #666666;
    line-height: 1.6;
  }
  