/* Main container styling */
.container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #ffffff;
    box-sizing: border-box;
  }
  
  /* Title styling */
  .title {
    font-size: 32px;
    font-weight: 700;
    color: #6a0dad; /* Purple color */
    margin-bottom: 24px;
    /* text-align: center; */
  }
  
  /* Cards container with fixed 2-column layout */
  .cardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Always two columns */
    gap: 24px; /* Space between cards */
    max-width: 1000px;
    width: 100%;
    height: 600px;
    box-sizing: border-box;
  }
  
  /* Card styling */
  .card {
    /* background-color: #f8f8f8; */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: left;
    transition: transform 0.2s ease; /* Smooth hover effect */
  }
  
  .card:hover {
    transform: translateY(-4px); /* Slight lift on hover */
  }
  
  /* Step indicator styling */
  .step {
    font-size: 12px;
    font-weight: 600;
    color: #7e7e7e;
    margin-bottom: 8px;
  }
  
  /* Image styling */
  .image {
    width: 100%;
    height: 55px;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  
  /* Card title styling */
  .cardTitle {
    font-size: 12px;
    font-weight: 900;
    color: #333;
    margin-bottom: 8px;
  }
  
  /* Card text styling */
  .cardText {
    font-size: 12px;
    color: #666;
    /* line-height: 1.1; */
  }
  