/* Main container styling */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;
  }
  
  /* Title styling */
  .title {
    font-size: 32px;
    font-weight: 700;
    color: #6a0dad; /* Purple color */
    margin-bottom: 24px;
    text-align: left;
    align-self: start;
  }
  
  /* Cards container with single-row layout */
  .cardsContainer {
    display: flex;
    justify-content: space-between; /* Evenly space out the cards */
    gap: 16px;
    width: 100%;
    max-width: 1000px; /* Center the content within this max-width */
    padding: 0 20px; /* Add padding to the sides for alignment */
  }
  
  /* Card styling */
  .card {
    background-color: #f8f8f8;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s ease;
    flex: 1; /* Allow cards to grow equally */
    max-width: 300px; /* Prevents cards from getting too wide on large screens */
    height: 280px;
  }
  
  .card:hover {
    transform: translateY(-4px); /* Slight lift on hover */
  }
  
  /* Image styling */
  .image {
    width: 100%;
    height: 120px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
    margin-bottom: 12px; /* Space between image and the text */
  }
  
  /* Name styling */
  .name {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
  }
  
  /* Title/Role text styling */
  .titleText {
    font-size: 14px;
    color: #666;
    font-weight: 400;
    line-height: 1.4;
    font-style: italic;
  }
  